import styled from 'styled-components';

const PageContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;

  @media (min-width: 600px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 900px) {
    max-width: 900px;
    margin: 0 auto;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  //@media (min-width: 1600px) {
  //  max-width: 1600px;
  //}
`;

export default PageContainer;
