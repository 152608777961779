/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import 'normalize.css';
import './global.css';
import Footer from './footer';
import { ParallaxProvider } from 'react-scroll-parallax';

const Layout = ({ children }) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const resizeListener = () => {
      let vh = window.document.documentElement.clientHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    // set resize listener
    resizeListener();
    window.addEventListener('resize', resizeListener);
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  });

  return (
    <>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => {
          return (
            <>
              <Header siteTitle={data.site.siteMetadata.title} />
              <ParallaxProvider>
                <main>{children}</main>
              </ParallaxProvider>
              <Footer />
            </>
          );
        }}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
