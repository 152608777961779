import React from 'react';
import styled from 'styled-components';
import PageContainer from './pageContainer';
import {graphql, Link, StaticQuery} from 'gatsby';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import {RichText} from 'prismic-reactjs';

const FooterContainer = styled.footer`
    padding-top: 32px;
    padding-bottom: 32px;
    background: #064f71;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 375px;

    @media (min-width: 600px) and (orientation: portrait) {
        height: 417px;
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @media (min-width: 900px) {
        padding-top: 64px;
        padding-bottom: 64px;
        height: 417px;
    }

    @media (min-width: 1200px) {
        padding-top: 96px;
        padding-bottom: 96px;
        height: 455px;
    }
`;

const MenuLink = styled(Link)`
    height: 48px;
    line-height: 48px;
    padding-left: 8px;
    padding-right: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
    text-decoration: none;

    &:hover {
        opacity: 1 !important;
    }

    @media (min-width: 600px) and (orientation: portrait) {
        height: 64px;
        line-height: 64px;
        font-size: 1.4rem;
    }

    @media (min-width: 900px) {
        height: 64px;
        line-height: 64px;
        font-size: 1.4rem;
    }

    @media (min-width: 1200px) {
        font-size: 1.6rem;
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const MenuLinkA = styled.a`
    height: 48px;
    line-height: 48px;
    padding-left: 8px;
    padding-right: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
    text-decoration: none;

    &:hover {
        opacity: 1 !important;
    }

    @media (min-width: 600px) and (orientation: portrait) {
        height: 64px;
        line-height: 64px;
        font-size: 1.4rem;
    }

    @media (min-width: 900px) {
        height: 64px;
        line-height: 64px;
        font-size: 1.4rem;
    }

    @media (min-width: 1200px) {
        font-size: 1.6rem;
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const Menu = styled.nav`
    &:hover {
        ${MenuLink} {
            opacity: 0.5;
        }
    }
`;

const SocialIcons = styled.nav`
    margin-top: 32px;
`;

const SocialIcon = styled.a`
    padding: 16px 12px;
`;

const Copyright = styled.div`
    font-size: 0.7em;
    opacity: 0.8;
    margin-top: 1rem;
`;

const Enquires = styled.p`
    margin-top: 32px;
    font-size: 0.8rem;
`;
const By = styled.p`
    margin-top: 32px;
    font-size: 0.7rem;
    opacity: 0.8;
`;

const FooterText = styled.div``;

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allFooters {
            edges {
              node {
                copyright_statement
                nav_links {
                  nav_title
                  nav_link
                }
                facebook_link {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                general_enquiries_email
                instagram {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                title
                website_by
                website_by_link {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {node} = data.prismic.allFooters.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      return (
        <FooterContainer>
          <PageContainer>
            <Menu>
              {node.general_enquiries_email && (
                <MenuLinkA
                  href={'mailto:' + RichText.asText(node.general_enquiries_email)}
                >
                  Contact us
                </MenuLinkA>
              )}
              {node.nav_links &&
                node.nav_links.map(
                  (navLink, index) =>
                    navLink.nav_link &&
                    navLink.nav_title && (
                      <MenuLink
                        key={index}
                        activeClassName={'active'}
                        partiallyActive={true}
                        to={RichText.asText(navLink.nav_link) + "/"}
                      >
                        {RichText.asText(navLink.nav_title)}
                      </MenuLink>
                    )
                )}
            </Menu>
            <SocialIcons>
              {node.facebook_link && (
                <SocialIcon
                  href={node.facebook_link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FaFacebook size={'2.5em'}/>
                </SocialIcon>
              )}
              {node.instagram && (
                <SocialIcon
                  href={node.instagram.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <FaInstagram size={'2.5em'}/>
                </SocialIcon>
              )}
            </SocialIcons>
            {node.general_enquiries_email && (
              <Enquires>
                General Inquiries:{' '}
                <a
                  href={
                    'mailto:' + RichText.asText(node.general_enquiries_email)
                  }
                >
                  {RichText.asText(node.general_enquiries_email)}
                </a>
              </Enquires>
            )}
            <By>
              Website by:{' '}
              <a
                href="https://www.sensiblelab.com?utm_source=honbo&utm_medium=referral&utm_campaign=client_website&utm_content=footer_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sensible Lab
              </a>
            </By>
            {node.copyright_statement && (
              <Copyright>
                © {new Date().getFullYear()}
                <FooterText>
                  <RichText render={node.copyright_statement}/>
                </FooterText>
              </Copyright>
            )}
          </PageContainer>
        </FooterContainer>
      );
    }}
  />
);

export default Footer;
