import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import PageContainer from './pageContainer';
import Image from 'gatsby-image';
import { RichText } from 'prismic-reactjs';
import ReactModal from './reactModal.js';
import { FiMenu } from 'react-icons/fi';

const HeaderContainer = styled.header`
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const HeaderInnerContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) and (orientation: portrait) {
    height: 96px;
  }

  @media (min-width: 900px) {
    height: 96px;
  }
`;

const MenuLinkA = styled.a`
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  text-decoration: none;

  @media (min-width: 600px) and (orientation: portrait) {
    height: 64px;
    line-height: 64px;
    font-size: 1.1rem;
  }

  @media (min-width: 900px) {
    height: 64px;
    line-height: 64px;
    font-size: 1.4rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.6rem;
    padding-left: 16px;
    padding-right: 16px;
  }

  &:hover {
    opacity: 1 !important;
  }

  &.active {
    color: #064f71;
  }
`;

const MenuLink = styled(Link)`
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  text-decoration: none;

  @media (min-width: 600px) and (orientation: portrait) {
    height: 64px;
    line-height: 64px;
    font-size: 1.1rem;
  }

  @media (min-width: 900px) {
    height: 64px;
    line-height: 64px;
    font-size: 1.4rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.6rem;
    padding-left: 16px;
    padding-right: 16px;
  }

  &:hover {
    opacity: 1 !important;
  }

  &.active {
    color: #064f71;
  }
`;

const MobileMenuLinkA = styled.a`
  color: #fff;
  display: inline-block;
  //height: 48px;
  //line-height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  text-decoration: none;
  font-size: 2rem;

  @media (min-width: 600px) and (orientation: portrait) {
    //height: 64px;
    //line-height: 64px;
    font-size: 1.1rem;
  }

  @media (min-width: 900px) {
    //height: 64px;
    //line-height: 64px;
    font-size: 1.4rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.6rem;
    padding-left: 16px;
    padding-right: 16px;
  }

  &:hover {
    opacity: 1 !important;
  }

  &.active {
    color: #064f71;
  }
`;

const MobileMenuLink = styled(Link)`
  color: #fff;
  display: inline-block;
  //height: 48px;
  //line-height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  text-decoration: none;
  font-size: 2rem;

  @media (min-width: 600px) and (orientation: portrait) {
    //height: 64px;
    //line-height: 64px;
    font-size: 1.1rem;
  }

  @media (min-width: 900px) {
    //height: 64px;
    //line-height: 64px;
    font-size: 1.4rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.6rem;
    padding-left: 16px;
    padding-right: 16px;
  }

  &:hover {
    opacity: 1 !important;
  }

  &.active {
    color: #064f71;
  }
`;

const Menu = styled.nav`
  display: none;
  margin-right: -8px;

  @media (min-width: 600px) {
    display: block;
  }

  @media (min-width: 1200px) {
    margin-right: -16px;
  }

  &:hover {
    ${MenuLink} {
      opacity: 0.5;
    }
  }
`;

const MobileLogo = styled.h1`
  width: 48px;
  margin: 0;

  @media (min-width: 600px) {
    display: none;
  }

  @media (min-width: 600px) and (orientation: portrait) {
    width: 80px;
  }
`;

const Logo = styled.h1`
  width: 48px;
  margin: 0;
  display: none;

  @media (min-width: 600px) {
    display: block;
  }

  @media (min-width: 600px) and (orientation: portrait) {
    width: 80px;
  }

  @media (min-width: 900px) {
    width: 80px;
  }
`;

const MobileButton = styled(Link)`
  width: 52px;
  height: 52px;
  padding: 10px;
  cursor: pointer;
  display: block;

  @media (min-width: 600px) {
    display: none;
  }
`;

const MobileFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.9);

  @media (min-width: 600px) {
    display: none;
  }
`;

const MobileFooterLink = styled(Link)`
  flex: 1;
  text-align: center;
  padding: 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
  justify-content: center;
`;

const MobileFooterLinkA = styled.a`
  flex: 1;
  text-align: center;
  padding: 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  justify-content: center;
`;

const Modal = styled(ReactModal)`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
    overflow: hidden;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  &__content {
    outline: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
`;

const MobileMenuButton = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const MobileMenuIcon = styled(FiMenu)`
  display: block;
  width: 2rem;
  height: 2rem;
  color: #064f71;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const MobileMenu = styled(ReactModal)`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10001;
    overflow: hidden;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  &__content {
    outline: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ChoiceContainer = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 4px;
`;

const Choice = styled.a`
  display: block;
  border: 2px solid #064f71;
  border-radius: 4px;
  padding: 8px 24px;
  margin-top: 16px;
  color: #064f71;
  text-decoration: none;
  text-align: center;

  &:first-child {
    margin-top: 0;
  }
`;

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "smileyFace.png" }) {
            childImageSharp {
              fixed(width: 32, height: 32) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          prismic {
            allHeaders {
              edges {
                node {
                  nav_links {
                    nav_title
                    nav_url
                  }
                  logo_image
                  logo_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 80) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  mobile_logo_image
                  mobile_logo_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 80) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { node } = data.prismic.allHeaders.edges.slice(0, 1).pop();
        if (!node) {
          return null;
        }

        return (
          <>
            <Modal
              isOpen={isActive}
              onRequestClose={() => setIsActive(false)}
              closeTimeoutMS={500}
            >
              <ChoiceContainer>
                <Choice
                  href={'https://deliveroo.hk/en/brands/honbo'}
                  target={'_blank'}
                >
                  Deliveroo
                </Choice>
                <Choice href={'https://www.honbo.delivery/'} target={'_blank'}>
                  Honbo Delivery (HK Wide)
                </Choice>
              </ChoiceContainer>
            </Modal>
            <MobileMenu
              isOpen={isMobileMenuActive}
              onRequestClose={() => setIsMobileMenuActive(false)}
              closeTimeoutMS={500}
            >
              {node.nav_links.map((navLink, index) => {
                const link = RichText.asText(navLink.nav_url);
                // console.log(link);
                if (link.startsWith('/')) {
                  return (
                    <MobileMenuLink
                      key={index}
                      activeClassName={'active'}
                      partiallyActive={true}
                      to={link}
                    >
                      {RichText.asText(navLink.nav_title)}
                    </MobileMenuLink>
                  );
                } else {
                  return (
                    <MobileMenuLinkA
                      key={index}
                      // activeClassName={'active'}
                      // partiallyActive={true}
                      href={link}
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      {RichText.asText(navLink.nav_title)}
                    </MobileMenuLinkA>
                  );
                }
              })}
            </MobileMenu>
            <HeaderContainer>
              <PageContainer>
                <HeaderInnerContainer>
                  <MobileMenuButton
                    onClick={() => {
                      setIsMobileMenuActive(true);
                    }}
                  >
                    <MobileMenuIcon />
                  </MobileMenuButton>
                  <Link to="/">
                    <MobileLogo>
                      <Image
                        fluid={
                          node.mobile_logo_imageSharp.childImageSharp.fluid
                        }
                        alt={node.mobile_logo_image.alt}
                      />
                    </MobileLogo>
                    <Logo>
                      <Image
                        fluid={node.logo_imageSharp.childImageSharp.fluid}
                        alt={node.logo_image.alt}
                      />
                    </Logo>
                  </Link>
                  <Menu>
                    {/*<MenuLink onClick={() => setIsActive(true)}>*/}
                    {/*  Order Now*/}
                    {/*</MenuLink>*/}
                    {node.nav_links.map((navLink, index) => {
                      const link = RichText.asText(navLink.nav_url);
                      // console.log(link);
                      if (link.startsWith('/')) {
                        return (
                          <MenuLink
                            key={index}
                            activeClassName={'active'}
                            partiallyActive={true}
                            to={link + '/'}
                          >
                            {RichText.asText(navLink.nav_title)}
                          </MenuLink>
                        );
                      } else {
                        return (
                          <MenuLinkA
                            key={index}
                            // activeClassName={'active'}
                            // partiallyActive={true}
                            href={link}
                            target={'_blank'}
                            rel="noopener noreferrer"
                          >
                            {RichText.asText(navLink.nav_title)}
                          </MenuLinkA>
                        );
                      }
                    })}
                  </Menu>
                  <MobileButton to={'/#location'}>
                    <Image
                      fixed={data.file.childImageSharp.fixed}
                      alt={'Smiley Face'}
                    />
                  </MobileButton>
                </HeaderInnerContainer>
              </PageContainer>
            </HeaderContainer>
            <MobileFooter>
              {/* <MobileFooterLink to={'/menu'}>Menu</MobileFooterLink> */}
              <MobileFooterLinkA
                target={'_blank'}
                href={'https://deliveroo.hk/en/brands/honbo'}
              >
                Deliveroo
              </MobileFooterLinkA>
              <MobileFooterLinkA
                href={'https://www.honbo.delivery/'}
                target={'_blank'}
              >
                Honbo Delivery
              </MobileFooterLinkA>
              {/* <MobileFooterLink to={'/locations'}>Locations</MobileFooterLink> */}
            </MobileFooter>
          </>
        );
      }}
    />
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
